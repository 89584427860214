exports.components = {
  "component---src-templates-404-404-tsx": () => import("./../../../src/templates/404/404.tsx" /* webpackChunkName: "component---src-templates-404-404-tsx" */),
  "component---src-templates-contact-page-contact-page-tsx": () => import("./../../../src/templates/contact-page/contact-page.tsx" /* webpackChunkName: "component---src-templates-contact-page-contact-page-tsx" */),
  "component---src-templates-governance-member-page-governance-member-tsx": () => import("./../../../src/templates/governance-member-page/governance-member.tsx" /* webpackChunkName: "component---src-templates-governance-member-page-governance-member-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-network-detail-network-detail-tsx": () => import("./../../../src/templates/network-detail/network-detail.tsx" /* webpackChunkName: "component---src-templates-network-detail-network-detail-tsx" */),
  "component---src-templates-news-all-news-all-news-page-tsx": () => import("./../../../src/templates/news/all-news/all-news-page.tsx" /* webpackChunkName: "component---src-templates-news-all-news-all-news-page-tsx" */),
  "component---src-templates-news-news-page-tsx": () => import("./../../../src/templates/news/news-page.tsx" /* webpackChunkName: "component---src-templates-news-news-page-tsx" */),
  "component---src-templates-newsroom-newsroom-tsx": () => import("./../../../src/templates/newsroom/newsroom.tsx" /* webpackChunkName: "component---src-templates-newsroom-newsroom-tsx" */),
  "component---src-templates-press-releases-all-press-releases-all-press-releases-page-tsx": () => import("./../../../src/templates/press-releases/all-press-releases/all-press-releases-page.tsx" /* webpackChunkName: "component---src-templates-press-releases-all-press-releases-all-press-releases-page-tsx" */),
  "component---src-templates-publications-all-publications-page-tsx": () => import("./../../../src/templates/publications/all-publications-page.tsx" /* webpackChunkName: "component---src-templates-publications-all-publications-page-tsx" */),
  "component---src-templates-pulse-home-page-home-page-tsx": () => import("./../../../src/templates/pulse/home-page/home-page.tsx" /* webpackChunkName: "component---src-templates-pulse-home-page-home-page-tsx" */),
  "component---src-templates-pulse-thematic-articles-page-thematic-articles-page-tsx": () => import("./../../../src/templates/pulse/thematic-articles-page/thematic-articles-page.tsx" /* webpackChunkName: "component---src-templates-pulse-thematic-articles-page-thematic-articles-page-tsx" */),
  "component---src-templates-search-results-search-results-tsx": () => import("./../../../src/templates/search-results/search-results.tsx" /* webpackChunkName: "component---src-templates-search-results-search-results-tsx" */),
  "component---src-templates-type-page-type-page-tsx": () => import("./../../../src/templates/type-page/type-page.tsx" /* webpackChunkName: "component---src-templates-type-page-type-page-tsx" */)
}

